
















































































































































import { Component, Vue } from "vue-property-decorator";
import Header from "@/components/header.vue"; // @ is an alias to /src
import productservice from "@/services/product-service";
import ProjectService from "@/services/project-service";
const moment = require('moment')
const VueQrcode = require('@chenfengyuan/vue-qrcode');

@Component({
  components: {
    Header,
    VueQrcode
  },
})
export default class InventoryOut extends Vue {
  public filter = null;
  public filterOn = [];
  trans = [];
  public currentItem = null;
  public currentIndex = -1;
  public currentPage = 1;
  public title = "";

  to_ddl_project = [];
  to_ddl_project_code :any = {};
  from_ddl_project = [];
  from_ddl_project_code :any = {};


  public fields = [
     {
      key: "from_project_tile",
      label: "From Location",
    },
   {
      key: "to_project_tile",
      label: "To Location",
    },
    {
      key: "po_num",
    },
    {
      key: "project_in_charge",
    },
    {
      key: "issue_by",
    },
    {
      key: "received_by",
    },
    {
      key: "exit_date",
      label : "Exit Date",
    }
  ];
  public columns = [
    {
      key: "from_project_tile",
      label: "From Location",
    },
   {
      key: "to_project_tile",
      label: "To Location",
    },
    {
      key: "po_num",
    },
    {
      key: "project_in_charge",
    },
    {
      key: "issue_by",
    },
    {
      key: "received_by",
    },
    {
      key: "exit_date",
      label : "Exit Date",
    }
  ];
           
  public items_fields = [
    { key: "sku" },
    { key: "title" },
    { key: "qty" },
  ];

  public items_field_history = [
    { key: "sku" },
    { key: "title" },
    { key: "qty" },
  ];



  get rows() {
    return this.selected_items.length;
  }
  public itemid = "";
  public master:any = {};
  public item_history = [];  
  public items = [];
  public selected_items = [
    {
    "to_project_tile" : "",
    "Location" : "",
    "invoice_no_delivery" : "",
    "po_num" : "",
    "employee_name" : "",
    "project_in_charge" : "",
    "transported_by" : "",
    "received_by" : "",
    "entance_date" : "" 
    },
  ];


submit(){
    if(this.to_ddl_project_code['id'] &&  this.from_ddl_project_code['id']){
      
      let obj = {
        from : this.from_ddl_project_code['id'],
        to: this.to_ddl_project_code['id']
      }
        productservice.post_item_reqeust_inout_report(obj)
            .then((response) => {
              this.selected_items = response.data.master;
          
            })
            .catch((e) => {
              console.log(e);
            });

      }else{ 
        productservice.get_item_reqeust_out(0)
            .then((response) => {
              this.selected_items = response.data.master;
          
            })
            .catch((e) => {
              console.log(e);
            });
      } 
    }

  onRowClicked(item: any, index: any, event: any) {
    console.log(item.id)
    productservice.get_item_reqeust_out(item.id)
      .then((response) => {
        let total = 0;
        this.items = response.data.items;  
        this.item_history = response.data.events;    
        this.master = response.data.master[0];
      })
      .catch((e) => {
        console.log(e);
      });
    this.$root.$emit("bv::toggle::collapse", "sidebar-variant");
  }
  mounted() {  
    this.submit()
  }

    to_search_project(title: any){
    if(title.length > 4){
      ProjectService.getByTitle(title)
      .then((response) => {  
        this.to_ddl_project = response.data.result;      
        })
      .catch((e) => {
        console.log(e);
      });
    }
    if(title.length == 0){this.to_ddl_project = [];} 
  }

    to_get_project(data: any){
    this.to_ddl_project_code = data;
    if(this.to_ddl_project_code){this.to_ddl_project = [];}
  }

  from_search_project(title: any){
    if(title.length > 4){
      ProjectService.getByTitle(title)
      .then((response) => {  
        this.from_ddl_project = response.data.result;      
        })
      .catch((e) => {
        console.log(e);
      });
    }
    if(title.length == 0){this.from_ddl_project = [];} 
  }

    from_get_project(data: any){
    this.from_ddl_project_code = data;
    if(this.from_ddl_project_code){this.from_ddl_project = [];}
  }

  clear(){
    this.to_ddl_project = [];
    this.to_ddl_project_code = {};
    this.from_ddl_project = [];
    this.from_ddl_project_code = {};
    this.selected_items = [];
  }

}
